import OmakaseSite from './OmakaseSite'
import './App.css';

function App() {
  return (
    <OmakaseSite></OmakaseSite>
  );
}

export default App;
